import {useParams} from "react-router";
import PageHeader from "~/components/PageHeader";
import apiClient, {getFetcher} from "~/lib/apiClient";
import useSWR from "swr";
import {get} from "lodash";
import React, {useEffect, useState} from "react";
import TextInput, { TextInputProps } from "~/components/form/TextInput";
import {Link} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import * as Form from "@radix-ui/react-form";
import SelectInput from "~/components/form/SelectInput";
import { Button, Callout, Checkbox, Card, Box, Text, Flex, Badge, Separator } from "@radix-ui/themes";
import { CopyIcon, CheckIcon, KeyIcon, EyeIcon, EyeOffIcon, HashIcon, CreditCardIcon, GlobeIcon } from "lucide-react";

interface CheckboxGroupProps {
  label: string;
  options: { label: string; value: string }[];
  selectedValues: string[];
  onChange: (selectedValues: string[]) => void;
}

interface Client {
  id: string;
  name: string;
  type: string;
  platform: string | null;
  environment: 'live' | 'test';
  secret: string;
  billing_provider_id: string | null;
  exclusion_rules: { rule: string; value: string }[] | null;
  allowed_origins: string[] | null;
  pending_origins: string[] | null;
  api_keys: any[];
  test_user_jwt: string;
}

interface FormData {
  name: string | null;
  billing_provider_id: string | null;
  exclusion_rules: string;
  allowed_origins: string[];
}

function SecretField({ secret }: { secret: string }) {
  const [isVisible, setIsVisible] = React.useState(false);
  
  const toggleVisibility = () => setIsVisible(!isVisible);
  
  const displayValue = isVisible ? secret : '•'.repeat(Math.min(32, secret.length));

  return (
    <Flex align="center" className="bg-gray-100 px-3 py-2 rounded">
      <code className="text-sm font-mono text-gray-800 flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
        {displayValue}
      </code>
      <Flex gap="2">
        <Button size="1" variant="ghost" onClick={toggleVisibility} className="min-w-[32px]">
          {isVisible ? <EyeOffIcon className="h-3 w-3" /> : <EyeIcon className="h-3 w-3" />}
        </Button>
        <Button size="1" variant="ghost" onClick={() => navigator.clipboard.writeText(secret)} className="min-w-[32px]">
          <CopyIcon className="h-3 w-3" />
        </Button>
      </Flex>
    </Flex>
  );
}

function CheckboxGroup({ label, options, selectedValues, onChange }: CheckboxGroupProps) {
  const handleChange = (value: string, checked: boolean) => {
    const newSelectedValues = checked
      ? [...selectedValues, value]
      : selectedValues.filter(v => v !== value);
    onChange(newSelectedValues);
  };

  return (
    <Box>
      <Text as="label" size="2" weight="medium" className="text-gray-800 mb-2 block">{label}</Text>
      <Box className="space-y-2">
        {options.map(option => (
          <Flex key={option.value} align="center" gap="2">
            <Checkbox
              id={option.value}
              checked={selectedValues.includes(option.value)}
              onCheckedChange={(checked) => handleChange(option.value, checked as boolean)}
            />
            <Text as="label" htmlFor={option.value} size="2" className="text-gray-800">
              {option.label}
            </Text>
          </Flex>
        ))}
      </Box>
    </Box>
  );
}

export default function ClientDetailPage() {
  const { clientId } = useParams<{ clientId: string }>();

  const { data: client, error } = useSWR<Client>(`/clients/${clientId}`, getFetcher);
  const { data: billingProviders, error: billingProvidersError } = useSWR(`/billing/providers`, getFetcher);

  const [data, setData] = useState<FormData>({
    name: null,
    billing_provider_id: null,
    exclusion_rules: "",
    allowed_origins: [],
  });
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [removedOrigins, setRemovedOrigins] = useState<string[]>([]);

  useEffect(() => {
    if (!client) return;
    setData({
      name: client.name,
      billing_provider_id: client.billing_provider_id,
      exclusion_rules: client.exclusion_rules
        ? client.exclusion_rules.map(rule => rule.value).join(",")
        : "",
      allowed_origins: client.allowed_origins || [],
    });
  }, [client]);

  function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    setIsLoading(true);
    setErrors({});

    const formattedData = {
      ...data,
      exclusion_rules: data.exclusion_rules.split(",").map(domain => ({
        rule: "emailDomainIs",
        value: domain.trim(),
      })),
    };

    apiClient.patch(`/clients/${clientId}`, formattedData)
      .then(res => {
        setIsLoading(false);
        setRemovedOrigins([]);
      })
      .catch(err => {
        setIsLoading(false);
        if (err.response?.data?.errors) {
          setErrors(err.response.data.errors);
        }
      });
  }

  function field(id: string, label: string): TextInputProps {
    return {
      id,
      label,
      value: get(data, id),
      onChange: (value) => setData({...data, [id]: value}),
      errors: getFieldError(id),
    }
  }

  function getFieldError(fieldName: string) {
    const errorMessages = Object.entries(errors)
      .filter(([key]) => key.startsWith(fieldName))
      .map(([, value]) => value)
      .flat();
    return errorMessages.length > 0 ? errorMessages : undefined;
  }

  function handleAllowedOriginsChange(selectedOrigins: string[]) {
    const newRemovedOrigins = (client?.allowed_origins || []).filter(
      origin => !selectedOrigins.includes(origin)
    );
    setRemovedOrigins(newRemovedOrigins);
    setData({ ...data, allowed_origins: selectedOrigins });
  }

  if (!client) return <div>Loading...</div>;
  if (error) return <div>Error loading client: {error}</div>;

  const allOrigins = [...(client.allowed_origins || []), ...(client.pending_origins || [])].filter((value, index, self) => self.indexOf(value) === index);
  const isTest = client.environment === 'test';

  return (
    <div className="px-8 py-6">
      <PageHeader navigation={<Link className="flex items-center" to="/settings/developers"><ChevronLeftIcon className="w-6 h-6 text-gray-800" /></Link>}>
        <Flex justify="between" align="center" className="gap-4">
          <Flex gap="3" align="center">
            <Text size="6" weight="bold">{client.name}</Text>
            <Badge variant="solid" color={isTest ? 'gray' : 'blue'} className="uppercase text-[10px]">
              {client.type}
            </Badge>
          </Flex>
          
          {client.platform && (
            <Flex align="center" gap="2" className="mt-1">
              <GlobeIcon className="h-3 w-3 text-gray-800" />
              <Text size="2" className="text-gray-800">{client.platform}</Text>
            </Flex>
          )}
          
          <Flex gap="4" align="center">
            <Flex gap="1" align="center">
              <CreditCardIcon className={`h-4 w-4 ${client.billing_provider_id ? 'text-green-600' : 'text-orange-600'}`} />
              <Text size="2" className={client.billing_provider_id ? 'text-green-600' : 'text-orange-600'}>
                {client.billing_provider_id ? 'Connected to billing' : 'No billing connected'}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </PageHeader>

      <Box className="mt-8 grid gap-6 lg:grid-cols-2">
        {/* Settings Form */}
        <Card className="p-6">
          <Form.Root onSubmit={handleSubmit} className="space-y-6">
            <Box>
              <Text size="3" weight="medium" className="text-gray-800 mb-4">Settings</Text>
              <Box className="space-y-4">
                <TextInput {...field('name', 'Name')} />

                {billingProviders && billingProviders.data?.length > 0 && (
                  <SelectInput
                    options={billingProviders.data}
                    {...field('billing_provider_id', 'Billing Provider')}
                  />
                )}

                {billingProviders && billingProviders.data?.length === 0 && (
                  <Callout.Root color="orange">No billing providers available</Callout.Root>
                )}
                {billingProvidersError && (
                  <Callout.Root color="red">Error loading billing providers: {billingProvidersError}</Callout.Root>
                )}

                <TextInput
                  {...field('exclusion_rules', 'Exclusion Rules')}
                  placeholder="Enter email domains separated by commas (e.g., example.com, test.com)"
                />

                <CheckboxGroup
                  label="Allowed Origins"
                  options={allOrigins.map(origin => ({ label: origin, value: origin }))}
                  selectedValues={data.allowed_origins}
                  onChange={handleAllowedOriginsChange}
                />

                {removedOrigins.length > 0 && (
                  <Callout.Root color="orange">
                    Warning: The following origins will be removed and not added back to pending:
                    {removedOrigins.join(", ")}
                  </Callout.Root>
                )}

                <Box>
                  <Button disabled={isLoading} type="submit">
                    {isLoading ? "Saving..." : "Save Changes"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form.Root>
        </Card>

        {/* Credentials Card */}
        <Card className="p-6">
          <Box className="space-y-6">
            <Text size="3" weight="medium" className="text-gray-800">Credentials</Text>
            
            <Box className="space-y-4">
              <div>
                <Flex align="center" gap="2" className="mb-2">
                  <HashIcon className="h-3 w-3 text-gray-800" />
                  <Text size="2" weight="medium" className="text-gray-800">Client ID</Text>
                </Flex>
                <Flex align="center" className="bg-gray-100 px-3 py-2 rounded">
                  <code className="text-sm font-mono text-gray-800 flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                    {client.id}
                  </code>
                  <Button size="1" variant="ghost" onClick={() => navigator.clipboard.writeText(client.id)} className="min-w-[32px]">
                    <CopyIcon className="h-3 w-3" />
                  </Button>
                </Flex>
              </div>

              <div>
                <Flex align="center" gap="2" className="mb-2">
                  <KeyIcon className="h-3 w-3 text-gray-800" />
                  <Text size="2" weight="medium" className="text-gray-800">Client Secret</Text>
                </Flex>
                <SecretField secret={client.secret} />
              </div>

              {client.test_user_jwt && (
                <div>
                  <Flex align="center" gap="2" className="mb-2">
                    <KeyIcon className="h-3 w-3 text-gray-800" />
                    <Text size="2" weight="medium" className="text-gray-800">Test User JWT</Text>
                  </Flex>
                  <SecretField secret={client.test_user_jwt} />
                </div>
              )}
            </Box>
          </Box>
        </Card>
      </Box>
    </div>
  );
}
