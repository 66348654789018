import PageHeader from "~/components/PageHeader";
import {Heading, TabNav} from "@radix-ui/themes";
import AnchorButton from "~/components/AnchorButton";
import React from "react";
import {Link, useLocation} from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList, BreadcrumbPage,
  BreadcrumbSeparator
} from "~/components/ui/breadcrumb";
import { ArrowLeftIcon } from "lucide-react";

export default function WorkflowLayout({ workflow, children }) {

  const location = useLocation();

  return (
    <div className="flex flex-col flex-grow">
      <div className="pt-10 px-10">
        <Heading >{workflow.name}</Heading>

        <PageHeader>
          <div className="pt-2">
            <Link to="/convert/workflows" className="flex items-center gap-1"><ArrowLeftIcon className="w-4 h-4 mr-2"/> Experiences</Link>
          </div>

          <div className="gap-4 flex items-center">
            {/* <AnchorButton variant="outline" href={`/convert/workflows/${workflow.id}/edit`}>Duplicate</AnchorButton> */}
            <AnchorButton variant="ghost" href={`/account/activities?flow=${workflow.id}`}>Activities </AnchorButton>
            <AnchorButton variant="surface" href={`/convert/workflows/${workflow.id}/edit`}>Edit </AnchorButton>
            {/* <AnchorButton variant="outline" href={`/convert/workflows/${workflow.id}/edit`}>Pause</AnchorButton> */}
          </div>
        </PageHeader>

        <div className="mb-4">
          <TabNav.Root size="2">
            <TabNav.Link asChild active={location.pathname === `/convert/workflows/${workflow.id}`}>
              <Link to={`/convert/workflows/${workflow.id}`}>Funnel</Link>
            </TabNav.Link>
            <TabNav.Link asChild active={location.pathname === `/convert/workflows/${workflow.id}/revenue`}>
              <Link to={`/convert/workflows/${workflow.id}/revenue`}>Revenue</Link>
            </TabNav.Link>
            <TabNav.Link  asChild active={location.pathname === `/convert/workflows/${workflow.id}/paywalls`}>
              <Link to={`/convert/workflows/${workflow.id}/paywalls`}>Build</Link>
            </TabNav.Link>
            <TabNav.Link asChild active={location.pathname === `/convert/workflows/${workflow.id}/participation`}>
              <Link to={`/convert/workflows/${workflow.id}/participation`}>Sessions</Link>
            </TabNav.Link>
            <TabNav.Link asChild active={location.pathname === `/convert/workflows/${workflow.id}/events`}>
              <Link to={`/convert/workflows/${workflow.id}/events`}>Events</Link>
            </TabNav.Link>
          </TabNav.Root>
        </div>
      </div>

      {children}
    </div>
  )
}
