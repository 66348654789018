import React from 'react';
import { Button } from "@radix-ui/themes";

interface PaginationResource {
  data: any[];
  links: {
    first: string | null;
    last: string | null;
    prev: string | null;
    next: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    path: string;
    per_page: number;
    to: number;
  };
}

interface PaginationProps {
  resource: PaginationResource;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ resource, onPageChange }) => {
  const handlePrevPage = () => onPageChange(resource.meta.current_page - 1);
  const handleNextPage = () => onPageChange(resource.meta.current_page + 1);

  return (
    <div className="flex justify-between items-center">
      <Button 
        onClick={handlePrevPage} 
        disabled={!resource.links.prev}
      >
        Previous
      </Button>
      <span>Page {resource.meta.current_page}</span>
      <Button 
        onClick={handleNextPage} 
        disabled={!resource.links.next}
      >
        Next
      </Button>
    </div>
  );
};

export default Pagination;