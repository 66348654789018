import { Heading, Flex, Select, Button, Skeleton, Table, Badge, Link, Avatar } from "@radix-ui/themes";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from "swr";
import apiClient, { getFetcher } from "~/lib/apiClient";
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import cx from "classnames";
import { useLocalStorage } from '~/hooks/useLocalStorage';
import { avatarLetters } from "../customers/CustomersListPage";
import { RadioTowerIcon } from "lucide-react";
import { Check, Loader2, ClipboardIcon, LinkIcon, ExternalLink, EyeIcon, EyeOffIcon } from "lucide-react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { toast } from 'react-toastify';
import { useAuth } from "~/hooks/useAuth";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function DateRangeFilter({ onFiltersChange }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [range, setRange] = useState('last_30_days');
  const [client, setClient] = useState('');
  const [paywall, setPaywall] = useState('');
  const [storedFilters, setStoredFilters] = useLocalStorage('dashboard-filters', null);

  // Load clients and paywalls
  const { data: clients } = useSWR('/clients', getFetcher);
  const { data: paywalls } = useSWR('/convert/paywalls', getFetcher);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlRange = params.get('range');
    const urlClient = params.get('client');
    const urlPaywall = params.get('paywall');

    if (!urlRange && !urlClient && !urlPaywall && storedFilters) {
      // No URL params but we have stored filters - apply them silently
      const filters = {
        range: storedFilters.range || 'last_30_days',
        client: storedFilters.client || '',
        paywall: storedFilters.paywall || '',
      };
      updateFilters(filters.range, filters.client, filters.paywall, false);
    } else {
      // Use URL params or defaults
      setRange(urlRange || 'last_30_days');
      setClient(urlClient || '');
      setPaywall(urlPaywall || '');
    }
  }, [location, storedFilters]);

  const updateFilters = (newRange, newClient, newPaywall, updateStorage = true) => {
    const params = new URLSearchParams();
    params.set('range', newRange);
    if (newClient) params.set('client', newClient);
    if (newPaywall) params.set('paywall', newPaywall);

    if (updateStorage) {
      setStoredFilters({
        range: newRange,
        client: newClient,
        paywall: newPaywall,
      });
    }

    navigate(`${location.pathname}?${params.toString()}`);
    onFiltersChange({ range: newRange, client: newClient, paywall: newPaywall });
  };

  const handleReset = () => {
    setStoredFilters(null);
    updateFilters('last_30_days', '', '');
  };

  return (
    <Flex gap="3" align="center">
      <Select.Root value={range} onValueChange={(value) => updateFilters(value, client, paywall)}>
        <Select.Trigger />
        <Select.Content>
          <Select.Item value="today">Today</Select.Item>
          <Select.Item value="yesterday">Yesterday</Select.Item>
          <Select.Item value="last_7_days">Last 7 Days</Select.Item>
          <Select.Item value="last_30_days">Last 30 Days</Select.Item>
          <Select.Item value="last_365_days">Last 365 Days</Select.Item>
          <Select.Item value="this_month">This Month</Select.Item>
          <Select.Item value="last_month">Last Month</Select.Item>
          <Select.Item value="this_year">This Year</Select.Item>
          <Select.Item value="last_year">Last Year</Select.Item>
          <Select.Item value="all_time">All Time</Select.Item>
        </Select.Content>
      </Select.Root>

      <Select.Root 
        value={client}
        onValueChange={(value) => updateFilters(range, value, paywall)}>
        <Select.Trigger placeholder="All Clients"/>
        <Select.Content>
          {clients?.data?.map((client) => (
            <Select.Item key={client.id} value={client.id.toString()}>
              {client.label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>

      <Button variant="outline" onClick={handleReset}>
        Reset
      </Button>
    </Flex>
  );
}
export function GlobalStatsComponent({ stats }) {

  const data = get(stats, 'data.stats', { });

  return (
    <div className="grid grid-cols-5 gap-4 bg-gray-950 text-white rounded-lg divide-x min-h-28 items-center divide-gray-900">
      <StatItem isLoading={stats.isLoading} label="Unique Views" stat={data?.unique_views} />
      <StatItem isLoading={stats.isLoading} label="Bounce rate" stat={data?.bounce_rate} />
      <StatItem isLoading={stats.isLoading} label="Conversions" stat={data?.conversions_count} />
      <StatItem isLoading={stats.isLoading} label="Conversion Rate" stat={data?.conversion_rate} />
      <StatItem isLoading={stats.isLoading} label="Total Revenue" stat={data?.revenue_sum} />
    </div>
  );
}

interface Stat {
  value: number;
  diff_percentage: number;
  diff_relative: string;
  negate?: boolean;
  formatter?: string;
}
interface StatItemProps {
  label: string;
  stat: any;
  isLoading: boolean;
}

function StatItem({ label, stat, isLoading }: StatItemProps) {
  if (isLoading) {
    return <div className="flex justify-center"><Skeleton /></div>;
  }
  if (!stat) {
    return null;
  }

  const formatValue = (value: number, formatter?: string) => {
    if (formatter === 'currency') {
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // notation: 'compact',
        // minimumFractionDigits: 0,
        // maximumFractionDigits: 1
      }).format(value / 100);
      
      // Convert K/M/B to lowercase
      return formatted; //.replace(/K|M|B/g, match => match.toLowerCase());
    }

    // For non-currency values, use compact notation
    const formatted = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1
    }).format(value);
    
    // Convert K/M/B to lowercase
    return formatted.replace(/K|M|B/g, match => match.toLowerCase());
  };

  return (
   <div className="flex h-full items-center justify-center">
     <div className="flex flex-col space-y-0.5 p-4">
      <div className="text-4xl font-medium leading-none">
        {stat.value}
      </div>
      <div className="text-sm tabular-nums">{label}</div>
      <div className="text-xs">
        {stat.diff_percentage !== null && (
          <>
            <span className={cx({
              "text-[#7EB500]": (!stat.negate && stat.diff_percentage > 0) || (stat.negate && stat.diff_percentage < 0),
              "text-red-500": (!stat.negate && stat.diff_percentage < 0) || (stat.negate && stat.diff_percentage > 0),
            })}>
              {stat.diff_percentage > 0 ? '+' : ''}{stat.diff_percentage}%
            </span>
            &nbsp;
            <span className="text-gray-500">
              {stat.diff_relative === 0 ? '-' : 
               `${stat.diff_relative > 0 ? '+' : ''}${formatValue(Math.round(stat.diff_relative), stat.formatter)} this period`}
            </span>
          </>
        )}
      </div>
    </div>
   </div>
  );
}

function OverviewGridComponent() {
  const location = useLocation();
  const [activeFilters, setActiveFilters] = useState(() => {
    // Initialize from URL params
    const params = new URLSearchParams(location.search);
    const range = params.get('range');
    const client = params.get('client');
    const paywall = params.get('paywall');
    
    // If custom range, get the date values
    const from = params.get('from') || '';
    const to = params.get('to') || '';

    // Check localStorage if no URL params
    const storedFilters = localStorage.getItem('dashboard-filters');
    const parsedStoredFilters = storedFilters ? JSON.parse(storedFilters) : null;

    // Use URL params if they exist, otherwise use stored filters, finally fall back to defaults
    return {
      range: range || (parsedStoredFilters?.range) || 'last_30_days',
      client: client || (parsedStoredFilters?.client) || '',
      paywall: paywall || (parsedStoredFilters?.paywall) || '',
      customRange: range === 'custom' 
        ? { from, to }
        : (parsedStoredFilters?.customRange || { from: '', to: '' })
    };
  });

  // Build query string for stats
  const queryString = new URLSearchParams({
    range: activeFilters.range,
    ...(activeFilters.client && { client: activeFilters.client }),
    ...(activeFilters.paywall && { paywall: activeFilters.paywall }),
    ...(activeFilters.range === 'custom' && {
      from: activeFilters.customRange.from,
      to: activeFilters.customRange.to
    })
  }).toString();

  const stats = useSWR(`/dashboard/stats?${queryString}`, getFetcher);

  // Listen for URL changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const range = params.get('range');
    const client = params.get('client');
    const paywall = params.get('paywall');
    
    if (range || client || paywall) {
      setActiveFilters(current => ({
        ...current,
        range: range || current.range,
        client: client || current.client,
        paywall: paywall || current.paywall,
        customRange: range === 'custom' 
          ? { 
              from: params.get('from') || current.customRange.from, 
              to: params.get('to') || current.customRange.to 
            }
          : current.customRange
      }));
    }
  }, [location.search]);

  return (
    <>
      <div className="flex gap-4">
        <DateRangeFilter 
          onFiltersChange={setActiveFilters}
        />
      </div>
      <GlobalStatsComponent stats={stats} />
    </>
  );
}

interface ActivityItem {
  date: string;
  views: number;
  starts: number;
  bounces: number;
  converts: number;
}

function ActivityComponent() { 
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const clientParam = params.get('client') ? `?client=${params.get('client')}` : '';

  const { data } = useSWR(`/dashboard/activity${clientParam}`, getFetcher);
  const activity = data || [];

  const chartData = {
    labels: activity.map(item => new Date(item.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Views',
        data: activity.map(item => item.views),
        backgroundColor: '#49B7D1',
        stack: 'stack1',
        barPercentage: 1,
        categoryPercentage: 0.8,
      },
      {
        label: 'Starts',
        data: activity.map(item => item.starts),
        backgroundColor: '#A1DCEB', 
        stack: 'stack1',
        barPercentage: 1,
        categoryPercentage: 0.8,
      },
      {
        label: 'Conversions',
        data: activity.map(item => item.converts),
        backgroundColor: '#7eb5007a', 
        stack: 'stack1',
        barPercentage: 1,
        categoryPercentage: 0.8,
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        display: false,
        offset: true,
        grid: {
          offset: false
        }
      },
      y: {
        stacked: true,
        display: false,
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: (context) => {
            return new Date(activity[context[0].dataIndex].date).toLocaleDateString();
          },
          label: (context) => {
            const label = context.dataset.label;
            const value = context.raw;
            return `${label}: ${value}`;
          }
        }
      }
    },
  };

  if (!activity?.length) {
    return <div>No activity data available</div>;
  }

  return (
    <div className="h-full flex flex-col">
      <Heading size="4">Activity</Heading>
      <div className="flex-1 flex bg-red-X100 flex-end max-h-[230px]">
        <Bar options={options} data={chartData} />
      </div>
    </div>
  );
}

interface RevenueItem {
  date: string;
  amount: number;
}
interface RevenueResponse {
  current: RevenueItem[];
  previous: RevenueItem[];
  totals: {
    current: number;
    difference: number;
    difference_percentage: number;
  }
}
function RevenueTrendComponent() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const clientParam = params.get('client') ? `?client=${params.get('client')}` : '';

  const { data } = useSWR<RevenueResponse>(`/dashboard/revenue${clientParam}`, getFetcher);

  if (!data) {
    return <div className="flex justify-center"><Skeleton /></div>;
  }

  // Calculate the maximum value from both current and previous datasets
  // Convert cents to dollars by dividing by 100
  const maxAmount = Math.max(
    ...data.current.map(item => item.amount / 100),
    ...data.previous.map(item => item.amount / 100)
  );

  // Function to get nice rounded max value and step size
  const getNiceScale = (max: number) => {
    // Ensure minimum of $100
    if (max < 100) max = 100;

    // Get the magnitude (10^n) just above the max value
    const magnitude = Math.pow(10, Math.floor(Math.log10(max)));
    
    // Round up to a nice number
    let niceMax = Math.ceil(max / magnitude) * magnitude;

    // If the nice number is more than 2x the max, divide by 2
    if (niceMax > max * 2) {
      niceMax = niceMax / 2;
    }

    return {
      max: niceMax,
      step: niceMax / 2
    };
  };

  const scale = getNiceScale(maxAmount);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: value >= 1000000 ? 'compact' : 'standard',
      maximumFractionDigits: value >= 1000000 ? 1 : 0
    }).format(value);
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
        border: {
          display: false,
        }
      },
      y: {
        display: true,
        min: 0,
        max: scale.max,
        grid: {
          color: '#E2E7FF',
          drawBorder: false,
          drawTicks: false,
          lineWidth: 1,
          borderDash: [5, 5],
        },
        ticks: {
          display: true,
          count: 3,
          callback: (value: number) => formatCurrency(value),
          padding: 10,
          align: 'end',
          color: '#6B7280',
          font: {
            size: 12,
          }
        },
        border: {
          display: false,
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: (context) => {
            return new Date(data.current[context[0].dataIndex].date).toLocaleDateString();
          },
          label: (context) => {
            const label = context.dataset.label;
            return `${label}: ${formatCurrency(context.raw as number)}`;
          }
        }
      }
    },
  };

  const chartData = {
    labels: data.current.map(item => new Date(item.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Current Period',
        data: data.current.map(item => item.amount / 100), // Convert cents to dollars
        borderColor: '#008DA7',
        backgroundColor: '#008DA7',
        fill: false,
        tension: 0,
        pointRadius: 0,
        borderWidth: 2,
      },
      {
        label: 'Previous Period',
        data: data.previous.map(item => item.amount / 100), // Convert cents to dollars
        borderColor: '#E2E7FF',
        backgroundColor: '#E2E7FF',
        fill: false,
        tension: 0,
        pointRadius: 0,
        borderWidth: 2,
      }
    ]
  };

  return (
    <div className="space-y-2">
      <Heading size="4">New Revenue</Heading>
      <div className="flex justify-between items-center">
        <div className="inline-flex text-baseline gap-2">
          <div className="text-lg font-medium">
            {formatCurrency(data.totals.current / 100)} {/* Convert cents to dollars */}
          </div>
          
          <div className={cx({
            "text-sm font-semibold flex items-center align-baseline": true,
            "text-[#7EB500]": data.totals.difference > 0,
            "text-[#FF0000]": data.totals.difference < 0,
          })}>
            {data.totals.difference > 0 ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 14 16" fill="none"><path d="M3.59424 9.59999H9.88409L6.73917 4.79999L3.59424 9.59999Z" fill="currentColor"/></svg>
            ) : (
              <div className="rotate-180">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 14 16" fill="none"><path d="M3.59424 9.59999H9.88409L6.73917 4.79999L3.59424 9.59999Z" fill="currentColor"/></svg>
              </div>
            )}
            {formatCurrency(data.totals.difference / 100)} {/* Convert cents to dollars */}
          </div>
          <div className="font-medium">vs {data.previous_period_name}</div>
        </div>
      </div>
      <Line options={options} data={chartData} />
    </div>
  );
}

export interface Conversion {
  id: string;
  created_at: string;
  customer: {
    email: string;
    avatar_url: string;
  };
  paywall: {
    name: string;
  };
}

function ConversionsListComponent() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const clientParam = params.get('client') ? `?client=${params.get('client')}` : '';

  const { data: conversions } = useSWR<Conversion[]>(`/dashboard/conversions${clientParam}`, getFetcher);

  if (!conversions) {
    return <div className="flex justify-center"><Skeleton /></div>;
  }

  return (
    <div className="space-y-4">

      <Heading size="4">Conversions</Heading>

      <div className="divide-y divide-gray-200 border-y border-gray-200 max-h-[220px] overflow-y-auto">
        {conversions?.data.map((conversion) => (
          <div 
            key={conversion.id}
            className="flex items-center justify-between py-1"
          >
            <div className="flex items-center space-x-3">

              {conversion.customer && (
                <Avatar 
                  src={conversion.customer?.avatar_url}
                  fallback={avatarLetters(conversion.customer)}
                  size={"1"}
                />
              )}
              <div className="flex flex-col">
                <div className="text-sm font-medium">{conversion.customer?.email || conversion.customer?.id}</div>
                <div className="text-xs text-gray-700 font-medium">
                  <time dateTime={conversion.created_at}>
                    {new Date(conversion.created_at).toLocaleDateString()} at{' '}
                    {new Date(conversion.created_at).toLocaleTimeString()}
                  </time>
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-semibold text-green-700 ">
                Upgrade
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

interface ActivityEvent {
  id: string;
  created_at: string;
  customer: {
    email: string;
    avatar_url: string;
  };
  trigger: string;
  paywall: {
    id: string;
    name: string;
  };
  latest_event: {
    id: number;
    name: string;
    created_at: string;
  };
}

function RecentActivityComponent() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const clientParam = params.get('client') ? `?client=${params.get('client')}` : '';

  const { data, error, isLoading } = useSWR<ActivityEvent[]>(`/dashboard/activities${clientParam}`, getFetcher);

  if (error) {
    return (
      <div className="rounded-lg border border-red-200 bg-red-50 p-4">
        <div className="text-sm text-red-600">
          Error loading recent activity: {error.message}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Table.Root variant="surface">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>User Info</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Trigger</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Variant</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Date</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Event</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {[...Array(5)].map((_, i) => (
            <Table.Row key={i}>
              <Table.Cell>
                <Flex align="center" gap="2">
                  <Skeleton className="w-8 h-8 rounded-full" />
                  <Skeleton className="w-32 h-4" />
                </Flex>
              </Table.Cell>
              <Table.Cell><Skeleton className="w-24 h-4" /></Table.Cell>
              <Table.Cell><Skeleton className="w-32 h-4" /></Table.Cell>
              <Table.Cell><Skeleton className="w-40 h-4" /></Table.Cell>
              <Table.Cell><Skeleton className="w-20 h-6 rounded-full" /></Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    );
  }

  if (!data?.length) {
    return (
      <div className="rounded-lg border border-gray-200 p-4">
        <div className="text-sm text-gray-800">
          No recent activity found
        </div>
      </div>
    );
  }

  return (
    <Table.Root variant="surface">
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell>User Info</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Trigger</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Variant</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Date</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Event</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data?.map((activity) => (
          <Table.Row key={activity.id}>
            <Table.Cell>
              <Flex align="center" gap="2">

                {activity.customer && (
                  <Avatar 
                    src={activity.customer?.avatar_url}
                    fallback={avatarLetters(activity.customer)}
                    size={"1"}
                  />
                )}

                <div className="font-semibold">{activity.customer?.email || activity.customer?.id}</div>
              </Flex>
            </Table.Cell>
            <Table.Cell>{activity.trigger}</Table.Cell>
            <Table.Cell>
              <Link href={`/convert/workflows/${activity.paywall.workflow}`} className="text-teal-800 hover:underline">
                {activity.paywall.name}
              </Link>
            </Table.Cell>
            <Table.Cell>
              <div className="">
                {new Date(activity.created_at).toLocaleDateString()} at{' '}
                {new Date(activity.created_at).toLocaleTimeString()}
              </div>
            </Table.Cell>
            <Table.Cell>
              <Badge color={activity.latest_event === 'converted' ? 'green' : 'gray'}>
                {activity.latest_event || 'unknown'}
              </Badge>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
}

function truncateKey(key: string) {
  if (!key) return '';
  if (key.length > 20) {
    return `${key.slice(0, 20)}...`;
  }
  return key;
}

// Update the base64url encoding function to properly handle equals signs and special characters
const b64UrlEncode = (obj: object) => {
  // Convert the object to a JSON string
  const jsonStr = JSON.stringify(obj);
  
  // Convert to base64 using btoa
  const base64 = btoa(jsonStr)
    // Convert base64 to base64url by replacing characters
    .replace(/\+/g, '-')    // Convert '+' to '-'
    .replace(/\//g, '_')    // Convert '/' to '_'
    .replace(/=+$/, '');    // Remove trailing '=' characters
  
  return base64;
};

// Update function signature to include clientSecret
const generateSampleJwt = (clientId: string, clientSecret: string, customerId: string = 'cust_123') => {
  try {
    // Create header and payload
    const header = {
      alg: 'HS256',
      typ: 'JWT',
      kid: clientId
    };
    
    const payload = {
      sub: customerId  // Just the customer ID is enough
    };

    // Base64Url encode header and payload
    const encodedHeader = b64UrlEncode(header);
    const encodedPayload = b64UrlEncode(payload);
    
    // Create real signature using HMAC-SHA256
    const textEncoder = new TextEncoder();
    const data = textEncoder.encode(`${encodedHeader}.${encodedPayload}`);
    const key = textEncoder.encode(clientSecret); // Use passed in secret

    // Use Web Crypto API to create proper HMAC signature
    return crypto.subtle.importKey(
      'raw',
      key,
      { name: 'HMAC', hash: 'SHA-256' },
      false,
      ['sign']
    ).then(cryptoKey => 
      crypto.subtle.sign(
        'HMAC',
        cryptoKey,
        data
      )
    ).then(signature => {
      const signatureBase64 = btoa(String.fromCharCode(...new Uint8Array(signature)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
      
      return `${encodedHeader}.${encodedPayload}.${signatureBase64}`;
    });
  } catch (err) {
    console.error(err);
    return Promise.resolve('eyJ0...'); // Fallback
  }
};

function OnboardingSteps() {
  const { data: stripeConnections } = useSWR('/billing/providers', getFetcher, {
    // Poll every 5 seconds while importing
    refreshInterval: (data) => {
      // Check if any connection is in 'importing' state
      const hasImportingConnection = data?.data?.some(
        conn => conn.current_state === 'importing'
      );
      return hasImportingConnection ? 5000 : 0;
    }
  });
  const { user } = useAuth();
  const { data: clients } = useSWR('/clients', getFetcher);
  const [scriptInstalled, setScriptInstalled] = useState(false);
  const [polling, setPolling] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('javascript');
  const [environment, setEnvironment] = useState('live');

    // Update the client filtering based on environment
    const webClient = clients?.data?.find(client => 
      client.type === 'web' && client.environment === environment
    );

  const { data: activityData } = useSWR(
    polling ? `/account/agents` : null, 
    getFetcher,
    { refreshInterval: polling ? 5000 : 0 }
  );

  const hasEvents = activityData && activityData.data.length > 0;

  const stripeConnection = stripeConnections?.data?.find(conn => 
    conn.type === 'stripe_test' || conn.type === 'stripe'
  );
  const stripeConnected = ['success', 'importing'].includes(stripeConnection?.current_state);

  const getClientSecret = () => {
    if (!webClient) return '<CLIENT_SECRET>';
    return webClient.secret;
  };

  // First add the getter for client ID
  const getClientId = () => {
    if (!webClient) return '<CLIENT_ID>';
    return webClient.id;
  };
  // Update the JWT examples to include kid:
  const jwtExamples = {
    javascript: `const jwt = require('jsonwebtoken');

const key = '${getClientSecret()}';
const payload = {
  customer: 'cust_123', // Customer ID from your system
};

const token = jwt.sign(payload, key, { 
  algorithm: 'HS256',
  header: {
    kid: '${getClientId()}' // Client ID as Key ID
  }
});`,

    php: `<?php
require_once 'vendor/autoload.php';
use Firebase\\JWT\\JWT;

$key = '${getClientSecret()}';
$payload = [
    'customer' => 'cust_123', // Customer ID from your system
];

$token = JWT::encode($payload, $key, 'HS256', '${getClientId()}'); // Pass Client ID as Key ID`,

    python: `import jwt
from datetime import datetime, timedelta

key = '${getClientSecret()}'
payload = {
    'customer': 'cust_123', # Customer ID from your system
}

headers = {
    'kid': '${getClientId()}' # Client ID as Key ID
}

token = jwt.encode(payload, key, algorithm='HS256', headers=headers)`
  };

  // Add state for the JWT
  const [jwt, setJwt] = useState('eyJ0...');

  // Add effect to generate JWT when client changes
  useEffect(() => {
    if (webClient) {
      generateSampleJwt(getClientId(), getClientSecret())
        .then(setJwt)
        .catch(() => setJwt('eyJ0...'));
    }
  }, [webClient]);

  // Update the script code to use the state value
  const scriptCode = `<!-- Add to your <head> tag -->
<script src="https://js.plandalf.com/v1.js"></script>
<script>
  // Replace this with a freshly generated JWT for each user
  const jwt = '${jwt}';
  window.plandalf = Plandalf(jwt);
</script>`;

  // Add step tracking
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);

  // Helper to mark step as complete
  const completeStep = (step: number) => {
    if (!completedSteps.includes(step)) {
      setCompletedSteps([...completedSteps, step]);
    }
    setCurrentStep(step + 1);
  };

  // Effect 1: Handle Stripe connection step
  useEffect(() => {
    if (stripeConnected && !completedSteps.includes(1)) {
      completeStep(1);
    }
  }, [stripeConnected, completedSteps]);

  // Effect 2: Handle events detection step
  useEffect(() => {
    if (hasEvents && !completedSteps.includes(4)) {
      completeStep(4);
    }
  }, [hasEvents, completedSteps]);

  // Effect 3: Handle polling state
  useEffect(() => {
    setPolling(!hasEvents);
  }, []);

  const renderStep = (stepNumber: number, title: string, children: React.ReactNode) => (
    <div className="group relative">
      {/* Step connector line - now for all steps */}
      {stepNumber < 5 && (
        <div className="absolute left-[15px] top-[32px] w-[2px] h-[calc(100%+32px)] bg-gray-200 -z-10" />
      )}
      
      <div className="flex gap-4">
        <div className={cx(
          "rounded-full h-8 w-8 flex items-center justify-center text-sm font-semibold shrink-0 relative z-10 transition-colors bg-gray-100 text-gray-900",
        )}>
          {stepNumber}
        </div>
        <div className="flex-1 min-w-0 pt-1">
          <h3 className="text-base font-semibold text-gray-900 mb-3">
            {title}
          </h3>
          <div className="ml-0">
            {children}
          </div>
        </div>
      </div>
    </div>
  );

  const codeBlockStyle = {
    background: 'transparent',
    margin: 0,
    padding: 0,
    fontSize: '12px',
    lineHeight: '1.5',
  };

  const handleContinueToDashboard = () => {
    apiClient.patch(`/organizations/${user.current_organization.id}`, {
      flags: {
        has_completed_onboarding: true
      }
    }).then(() => {
      window.location.href = '/convert/home';
    });
  };

  // First, let's add some base styles for the step layout
  const stepContentStyle = "grid grid-cols-2 gap-6 items-start";
  const stepInstructionStyle = "text-sm text-gray-800 space-y-3"; // Smaller text
  const stepCodeStyle = "space-y-3";

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-1 py-8">
        <div className="max-w-4xl mx-auto px-4 space-y-8">
          {/* Header */}
          <div className="text-center space-y-2 pt-4">
            <div className="inline-flex items-center gap-2 px-2.5 py-1 rounded-full bg-blue-50 text-blue-700 text-xs font-medium">
              <Loader2 className="w-3 h-3 animate-spin" />
              Setting up your analytics
            </div>
            <h1 className="text-2xl font-bold text-gray-900">
              Let's get you started
            </h1>
            <p className="text-gray-800 max-w-md mx-auto">
              Complete these quick steps to start tracking your revenue
            </p>
          </div>

          {/* Steps */}
          <div className="space-y-16">
            {/* Step 1: Connect Stripe */}
            {renderStep(1, "Connect your Stripe account", (
              <div className="flex gap-6 rounded-lg p-2.5 bg-gray-100">
                <div className={cx(
                  "rounded-full h-10 w-10 flex items-center justify-center text-base font-semibold transition-colors mt-1",
                  {
                    "bg-gray-100 text-gray-900 group-hover:bg-gray-200": !stripeConnected,
                    "bg-green-100/70 text-green-800": stripeConnected && stripeConnection?.current_state !== 'importing',
                    "bg-blue-100/70 text-blue-800": stripeConnection?.current_state === 'importing'
                  }
                )}>
                  {stripeConnection?.current_state === 'importing' ? (
                    <Loader2 className="w-5 h-5 animate-spin"/>
                  ) : stripeConnected ? (
                    <Check className="w-5 h-5"/>
                  ) : (
                    <LinkIcon className="w-5 h-5"/>
                  )}
                </div>
                <div className="flex-1 min-w-0 space-y-4">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 mb-1">
                      {stripeConnection?.current_state === 'importing' ? (
                        <span className="flex items-center gap-2">
                          Importing Stripe Data
                          <Badge color="blue" variant="soft">
                            <span className="flex items-center gap-1.5">
                              <div className="w-1.5 h-1.5 rounded-full bg-blue-500 animate-pulse"/>
                              Importing
                            </span>
                          </Badge>
                        </span>
                      ) : stripeConnected ? (
                        <span className="flex items-center gap-2">
                          Connected to Stripe
                          <Badge color="green" variant="soft">
                            <span className="flex items-center gap-1.5">
                              <div className="w-1.5 h-1.5 rounded-full bg-green-500 animate-pulse"/>
                              Active
                            </span>
                          </Badge>
                        </span>
                      ) : (
                        "Connect your Stripe account"
                      )}
                    </h3>
                    {stripeConnected ? (
                      <div className="space-y-2">
                        <p className="text-gray-800">
                          Your Stripe account is connected and ready to process payments
                        </p>
                        <div className="flex items-center gap-3 text-sm">
                          <div className="flex items-center gap-1.5 text-gray-800">
                            <img 
                              src={stripeConnection?.integration.icon_url || "https://stripe.com/favicon.ico"}
                              alt="Stripe"
                              className="w-4 h-4 rounded"
                            />
                            {stripeConnection?.name}
                          </div>
                          {stripeConnection?.external_url && (
                            <Link 
                              href={stripeConnection.external_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="inline-flex items-center gap-1.5 text-blue-600 hover:text-blue-700 font-medium"
                            >
                              View in Stripe
                              <ExternalLink className="w-3.5 h-3.5" />
                            </Link>
                          )}
                        </div>
                      </div>
                    ) : (
                      <p className="text-gray-700">
                        Link your Stripe account to start analyzing your revenue data
                      </p>
                    )}
                  </div>

                  {/* Operations list moved inside */}
                  {stripeConnection?.operations?.length > 0 && (
                    <div className="space-y-3 border-t border-gray-200 pt-4 mt-4">
                      <table className="w-full text-left">
                        <tbody>
                        {stripeConnection.operations.map((operation, index) => (
                          <tr key={index} className="border-b border-gray-200/50 last:border-0">
                            <td className="py-2" valign="top">
                              <div className="font-medium text-sm">{operation.name}</div>
                              <div className="text-gray-600 text-xs">{operation.description}</div>
                            </td>

                            <td className="py-2" valign="top">
                              <div className="flex gap-2">
                                <div className={cx({
                                  "flex items-center text-xs px-2 py-0.5 font-medium rounded-full": true,
                                  "text-green-500": operation.status === "completed",
                                  "text-red-500": operation.status === "failed",
                                  "bg-blue-100 text-blue-700": operation.status === "running",
                                  "text-gray-500": operation.status === "pending",
                                  "text-purple-500": operation.status === "queued",
                                  "text-purple-500": operation.status === "retrying",
                                  "bg-gray-500 text-gray-200": operation.status === "cancelled"
                                })}>
                                  {operation.status}
                                </div>
                                {operation.meta && (
                                  <span className="text-xs text-gray-600">
                                    ({(operation.meta.total_jobs - operation.meta.pending_jobs)}/{operation.meta.total_jobs})
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="py-2 text-xs" valign="top">
                              {operation.metadata && (
                                <div className="leading-snug text-gray-600">
                                  {Object.keys(operation?.metadata).map((key, index) => (
                                    <div key={index}>
                                      <span>{key}:</span>{' '}
                                      <span className="text-blue-600 font-medium">{operation.metadata[key]}</span>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </td>
                            <td className="py-2 text-xs" valign="top">
                              {(operation.status === 'running' || operation.status === 'failed') && (
                                <div className="text-gray-600">
                                  {operation.runtime} seconds total runtime
                                  {(operation.runtime > 300 || operation.meta.failed_jobs > 0) && (
                                    <div className="mt-1">
                                      <Link 
                                        href={`/settings/connections/${stripeConnection.id}`}
                                        className="inline-flex items-center gap-1.5 text-red-600 hover:text-red-700 font-medium text-xs"
                                      >
                                        View Details
                                        <ExternalLink className="w-3 h-3" />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              )}
                              {operation.status === 'finished' && (
                                <Link 
                                  href={`/settings/connections/${stripeConnection.id}`}
                                  className="inline-flex items-center gap-1.5 text-green-600 hover:text-green-700 font-medium text-xs"
                                >
                                  View Details
                                  <ExternalLink className="w-3 h-3" />
                                </Link>
                              )}
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <Button 
                  size="3"
                  disabled={stripeConnected}
                  onClick={() => window.location.href = '/settings/integrations/create?provider=stripe&redirect_uri=/convert/home'}
                  className={cx(
                    "shrink-0 transition-all duration-200 font-medium",
                    {
                      "opacity-0": stripeConnected,
                      "bg-[#008DA7] hover:bg-[#0A7C91] active:bg-[#0A7C91]": !stripeConnected
                    }
                  )}
                >
                  {stripeConnected ? 'Connected' : 'Connect Stripe'}
                </Button>
              </div>
            ))}

            {/* Step 2: Get your Client Secret */}
            {renderStep(2, "Get your Client Secret", (
              <div className={stepContentStyle}>
                {/* Left: Instructions */}
                <div className={stepInstructionStyle}>
                  <p>Get your client secret to authenticate your requests. Choose the appropriate environment:</p>

                  <div className="p-2.5 bg-yellow-50">
                    <div className="flex gap-2">
                      <div className="p-1">⚠️</div>
                      <div className="text-yellow-800">
                        <strong>Environment Note:</strong> Use test client secrets for development/staging environments. 
                        For production, create a new client in live mode from the <Link href="/settings/clients" className="text-blue-600 hover:underline">clients page</Link>.
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right: Interactive/Code Content */}
                <div className={stepCodeStyle}>
                  {/* Environment selector */}
                  <div className="flex items-center gap-3 mb-4">
                    <div className="text-sm font-medium text-gray-700">Environment:</div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => setEnvironment('test')}
                        className={cx(
                          "px-3 py-1 text-sm font-medium rounded-md transition-colors",
                          {
                            "bg-blue-100 text-blue-700": environment === 'test',
                            "bg-gray-100 text-gray-800 hover:bg-gray-200": environment !== 'test'
                          }
                        )}
                      >
                        Test Mode
                      </button>
                      <button
                        onClick={() => setEnvironment('live')}
                        className={cx(
                          "px-3 py-1 text-sm font-medium rounded-md transition-colors",
                          {
                            "bg-blue-100 text-blue-700": environment === 'live',
                            "bg-gray-100 text-gray-800 hover:bg-gray-200": environment !== 'live'
                          }
                        )}
                      >
                        Live Mode
                      </button>
                    </div>
                  </div>

                  {/* Environment Variables Display */}
                  <div className="space-y-3">
                    {/* Environment Variables Code Block */}
                    <div className="relative">
                      <div className="absolute -inset-px bg-gradient-to-r from-[#008DA7] to-[#0A7C91] rounded-lg opacity-75 group-hover:opacity-100 transition-opacity" />
                      <div className="relative bg-gray-950 rounded-lg overflow-hidden">
                        <div className="flex items-center justify-between px-4 py-2 bg-gray-900/50">
                          <span className="text-sm text-gray-400 font-medium">
                            .env
                          </span>
                          <Button
                            size="1"
                            onClick={() => {
                              const envVars = `PLANDALF_CLIENT_ID=${getClientId()}\nPLANDALF_CLIENT_SECRET=${getClientSecret()}`;
                              navigator.clipboard.writeText(envVars);
                              toast.success('Environment variables copied to clipboard');
                            }}
                            className="bg-white/10 hover:bg-white/20 transition-colors"
                          >
                            <span className="flex items-center gap-1.5 text-sm">
                              <ClipboardIcon className="w-3.5 h-3.5" />
                              Copy
                            </span>
                          </Button>
                        </div>
                        <div className="p-4">
                          <SyntaxHighlighter
                            language="bash"
                            style={vscDarkPlus}
                            customStyle={codeBlockStyle}
                          >
                            {`PLANDALF_CLIENT_ID=${getClientId()}\nPLANDALF_CLIENT_SECRET=${getClientSecret()}`}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Step 3: Generate JWT Token */}
            {renderStep(3, "Generate JWT Token", (
              <div className={stepContentStyle}>
                {/* Left: Instructions */}
                <div className={stepInstructionStyle}>
                  <p>Generate a JWT token to authenticate your customers:</p>
                  <ul className="list-disc pl-4 space-y-1 ">
                    <li>Create a new token for each customer session</li>
                    <li>Include the customer ID in the <code className="text-xs bg-gray-100 px-1 rounded">sub</code> claim</li>
                    <li>Set an expiration time to limit token validity</li>
                  </ul>
                </div>

                {/* Right: Code Examples */}
                <div className={stepCodeStyle}>
                  {/* Language selector and code examples */}
                  <div className="flex items-center gap-2">
                    {['javascript', 'php', 'python'].map((lang) => (
                      <button
                        key={lang}
                        onClick={() => setSelectedLanguage(lang)}
                        className={cx(
                          "px-2.5 py-1 text-xs font-medium rounded-md transition-colors",
                          {
                            "bg-blue-100 text-blue-700": selectedLanguage === lang,
                            "bg-gray-100 text-gray-800 hover:bg-gray-200": selectedLanguage !== lang
                          }
                        )}
                      >
                        {lang === 'javascript' ? 'Node.js' : lang.charAt(0).toUpperCase() + lang.slice(1)}
                      </button>
                    ))}
                  </div>

                  {/* JWT Generation Example */}
                  <div className="relative">
                    <div className="absolute -inset-px bg-gradient-to-r from-[#008DA7] to-[#0A7C91] rounded-lg opacity-75 group-hover:opacity-100 transition-opacity" />
                    <div className="relative bg-gray-950 rounded-lg overflow-hidden">
                      <div className="flex items-center justify-between px-4 py-2 bg-gray-900/80">
                        <span className="text-sm text-gray-400 font-medium">
                          Generate JWT Token
                        </span>
                        <Button
                          size="1"
                          onClick={() => {
                            navigator.clipboard.writeText(jwtExamples[selectedLanguage]);
                            toast.success('Code copied to clipboard');
                          }}
                          className="bg-white/10 hover:bg-white/20 transition-colors"
                        >
                          <span className="flex items-center gap-1.5 text-sm">
                            <ClipboardIcon className="w-3.5 h-3.5" />
                            Copy code
                          </span>
                        </Button>
                      </div>
                      <div className="p-4 bg-gray-950">
                        <SyntaxHighlighter
                          language={selectedLanguage}
                          style={vscDarkPlus}
                          customStyle={codeBlockStyle}
                        >
                          {jwtExamples[selectedLanguage]}
                        </SyntaxHighlighter>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Step 4: Install Script */}
            {renderStep(4, "Install the script", (
              <div className={stepContentStyle}>
                {/* Left: Instructions */}
                <div className={stepInstructionStyle}>
                  <p>Add our script to your website's <code className="text-blue-600 bg-blue-50 px-1.5 py-0.5 rounded-md text-sm font-mono">&lt;head&gt;</code> tag</p>
                  
                  <div className="p-2.5 bg-yellow-50 border border-yellow-200 rounded-lg text-xs mt-4">
                    <div className="flex gap-2">
                      <div className="p-1">⚠️</div>
                      <div className="text-yellow-800">
                        <strong>Note:</strong> Make sure to replace the JWT token with a freshly generated one for each user session.
                        See the JWT generation example above.
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right: Code */}
                <div className={stepCodeStyle}>
                  <div className="relative">
                    <div className="absolute -inset-px bg-gradient-to-r from-[#008DA7] to-[#0A7C91] rounded-lg opacity-75 group-hover:opacity-100 transition-opacity" />
                    <div className="relative bg-gray-950 rounded-lg overflow-hidden">
                      <div className="flex items-center justify-between px-4 py-2 bg-gray-900/50">
                        <span className="text-sm text-gray-400 font-medium">
                          Installation
                        </span>
                        <Button
                          size="1"
                          onClick={() => {
                            navigator.clipboard.writeText(scriptCode.trim());
                            toast.success('Script copied to clipboard', {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                            });
                          }}
                          className="bg-white/10 hover:bg-white/20 transition-colors"
                        >
                          <span className="flex items-center gap-1.5 text-sm">
                            <ClipboardIcon className="w-3.5 h-3.5" />
                            Copy code
                          </span>
                        </Button>
                      </div>
                      <div className="p-4">
                        <SyntaxHighlighter
                          language="html"
                          style={vscDarkPlus}
                          customStyle={codeBlockStyle}
                        >
                          {scriptCode}
                        </SyntaxHighlighter>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Step 5: Verify Installation */}
            {renderStep(5, "Verify installation", (
              <div className="space-y-4">
                {polling && !hasEvents ? (
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-3 p-4 bg-blue-50 text-blue-700 rounded-lg">
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <div>
                        <p className="font-medium">Waiting for first event...</p>
                        <p className="text-sm text-blue-600">This usually takes about 30 seconds</p>
                      </div>
                    </div>
                    
                    <div className="p-4 bg-gray-50 rounded-lg space-y-3">
                      <h4 className="font-medium text-gray-900">While you wait:</h4>
                      <ul className="space-y-2 text-sm text-gray-700">
                        <li className="flex items-start gap-2">
                          <div className="mt-1 w-1.5 h-1.5 rounded-full bg-gray-400"/>
                          Make sure you've installed the script in your website's <code className="text-xs bg-gray-100 px-1 rounded">&lt;head&gt;</code> tag
                        </li>
                        <li className="flex items-start gap-2">
                          <div className="mt-1 w-1.5 h-1.5 rounded-full bg-gray-400"/>
                          Verify that you're generating a valid JWT token for your users
                        </li>
                        <li className="flex items-start gap-2">
                          <div className="mt-1 w-1.5 h-1.5 rounded-full bg-gray-400"/>
                          Test your integration by visiting your website and triggering some events
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : hasEvents ? (
                  <div className="space-y-4">
                    <div className="flex items-center gap-3 p-4 bg-green-50 text-green-700 rounded-lg">
                    
                      <div>
                        <p className="font-medium">Installation verified!</p>
                        <p className="text-sm text-green-600">We're receiving events from your website</p>

                        <div className="flex items-center gap-2"><Check className="w-3 h-3" /> <b>User:</b> {activityData.data[0].lookup_key}</div>
                      </div>
                    </div>
                    
                    <Button 
                      size="3"
                      onClick={handleContinueToDashboard}
                      className="w-full bg-[#008DA7] hover:bg-[#0A7C91] transition-all duration-200 font-medium"
                    >
                      Continue to Dashboard
                    </Button>
                  </div>
                ) : (
                  <p className="text-gray-700">
                    Copy and install the script above, then wait for verification...
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ConvertHomePage() {
  const { user } = useAuth();

  const { data: activityData } = useSWR('/dashboard/activity', getFetcher);
  const { data: organization } = useSWR(`/organizations/${user?.current_organization.id}`, getFetcher);
  const hasActivity = organization && organization.flags?.has_completed_onboarding == true;

  // If there's no activity, show onboarding
  if (!hasActivity && organization) {
    return <OnboardingSteps />;
  }

  // Existing dashboard content
  return (
    <div className="p-10 space-y-4">
      <div className="flex items-center justify-between">
        <Heading size="6">Your Overview</Heading>
        <Link href="/account/signals" className="text-link flex items-center gap-1">
          <RadioTowerIcon className="w-4 h-4" /> Signals
        </Link>
      </div>

      <OverviewGridComponent/>

      <div className="py-3 px-1">
      <hr />
      </div>

      <div className="grid grid-cols-3 gap-8 px-4 pb-4">
        <div className=""> 
          <RevenueTrendComponent/>
        </div>
        <div className=""> 
          <ActivityComponent/>
        </div>
        <div className=""> 
          <ConversionsListComponent/>
        </div>
      </div>

      <div className="px-4 pb-4 space-y-2 ">
        <Heading size="4">Recent Activity</Heading>
        <RecentActivityComponent/>
      </div>
    </div>
  )
}
