import { Navigate, Outlet, RouteObject, useLocation, useRoutes } from 'react-router-dom'
import React, { ComponentType, lazy, ReactNode, Suspense, useEffect } from "react";
import CustomersListPage from "~/pages/customers/CustomersListPage";
import WorkflowsListPage from "~/pages/convert/workflows/WorkflowsListPage";
import WorkflowDetailPage from "~/pages/convert/workflows/WorkflowDetailPage";
import PricingSchemeListPage from "~/pages/catalog/pricing/PricingSchemeListPage";
import CustomerDetailPage from "~/pages/customers/CustomerDetailPage";
import PricingSchemeDetailPage from "~/pages/catalog/pricing/PricingSchemeDetailPage";
import WorkflowCreatePage from "~/pages/convert/workflows/WorkflowCreatePage";
import PaywallCreatePage from "~/pages/convert/paywalls/PaywallCreatePage";
import ClientDetailPage from "~/pages/settings/clients/ClientDetailPage";
import PaywallEditPage from "~/pages/convert/paywalls/PaywallEditPage";
import WorkflowEditPage from "~/pages/convert/workflows/WorkflowEditPage";
import PricingSchemeCreatePage from "~/pages/catalog/pricing/PricingSchemeCreatePage";
import CatalogIndexPage from "~/pages/catalog";
import CatalogLayout from "~/layouts/CatalogLayout";
import SettingsLayout from "~/layouts/SettingsLayout";
import FeatureCreatePage from "~/pages/catalog/features/FeatureCreatePage";
import PlanCreatePage from "~/pages/catalog/plans/PlanCreatePage";
import FeatureSetCreatePage from "~/pages/catalog/feature_sets/FeatureSetCreatePage";
import FeatureSetListPage from "~/pages/catalog/feature_sets/FeatureSetListPage";
import TeamListPage from "~/pages/settings/team/TeamListPage";
import ThemeEditPage from "~/pages/settings/ThemeEditPage";
import WorkflowSandboxPage from "~/pages/convert/WorkflowSandboxPage";
import SchemePackageCreatePage from "~/pages/catalog/pricing/SchemePackageCreatePage";
import ProductFamiliesIndexPage from "~/pages/catalog/product_families/ProductFamiliesIndexPage";
import { Button, Spinner } from "@radix-ui/themes";
import PackageDetailPage from "~/pages/catalog/pricing/PackageDetailPage";
import WorkflowPaywallsPage from "~/pages/convert/workflows/WorkflowPaywallsPage";
import WorkflowResultsPage from "~/pages/convert/workflows/WorkflowResultsPage";
import WorkflowParticipationPage from "~/pages/convert/workflows/WorkflowParticipationPage";
import TestPage from '~/pages/TestPage';
import CreateEditProductFamilyPage from '~/pages/catalog/product_families/CreateEditProductFamilyPage';
import WorkflowEventsPage from '~/pages/convert/workflows/WorkflowEventsPage';
import WorkflowRevenuePage from '~/pages/convert/workflows/WorkflowRevenuePage';
import { PaywallViewPage } from '~/pages/convert/paywalls/PaywallViewPage';
import ElementsListPage from '~/pages/convert/elements/ElementsListPage';
import ElementDetailPage from '~/pages/convert/elements/ElementDetailPage';
import FlowDetailPage from '~/pages/convert/flows/FlowDetailPage';
import UsageEventsListPage from '~/pages/mgmt/UsageEventsListPage';
import ChargesListPage from '~/pages/billing/charges/ChargesListPage';
import CreateMetricPage from '~/pages/catalog/metrics/CreateMetricPage';
import MetricsListPage from '~/pages/catalog/metrics/MetricsListPage';
import FeatureSetEditPage from "~/pages/catalog/feature_sets/FeatureSetEditPage";
import MetricEditPage from '~/pages/catalog/metrics/MetricEditPage';
import AttributionsListPage from '~/pages/mgmt/AttributionsListPage';
import ProductCreatePage from '~/pages/catalog/products/ProductCreatePage';
import ConvertHomePage from '~/pages/convert/ConvertHomePage';
import SignalsListPage from '~/pages/account/SignalsListPage';
import ActivitiesListPage from '~/pages/account/ActivitiesListPage';
import AgentsListPage from '~/pages/mgmt/AgentsListPage';


const retry = (
  fn: Function,
  retriesLeft: number = 2,
  interval: number = 1000,
): Promise<{ default: ComponentType<unknown> }> => {
  return new Promise((resolve) => {
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          if (retriesLeft === 0) {
            return window.location.reload() // refresh the page as last resort
          }

          retry(fn, retriesLeft - 1, interval)
        }, interval)
      })
  })
}


export const lazyLoad = (fn: Function) => lazy(() => retry(fn))



const Error404 = lazyLoad(() => import(/* webpackChunkName: 'error-404' */ '~/pages/Error404'))

const Dashboard = lazyLoad(() => import(/* webpackChunkName: 'dashboard' */ '~/pages/Dashboard'))

const DashboardLayout = lazyLoad(
  () => import(/* webpackChunkName: 'side-nav-layout' */ '~/layouts/DashboardLayout'),
)

const LoginPage = lazyLoad(() => import(/* webpackChunkName: 'login' */ '~/pages/auth/Login'))
const RegisterPage = lazyLoad(() => import(/* webpackChunkName: 'login' */ '~/pages/auth/Register'))

interface CustomRouteObject extends Omit<RouteObject, 'children' | 'path'> {
  path?: string | string[]
  private?: boolean
  onlyPublic?: boolean
  invitation?: boolean
  redirect?: string
  children?: CustomRouteObject[]
}

const authRoutes: CustomRouteObject[] = [
  { path: '/auth/login', element: <LoginPage />, onlyPublic: true },
  { path: '/auth/register', element: <RegisterPage />, onlyPublic: true },
]


const IntegrationCreatePage = lazyLoad(() => import(/* webpackChunkName: 'integration-create' */ '~/pages/settings/integrations/Create'))
const IntegrationsListPage = lazyLoad(() => import(/* webpackChunkName: 'integration-create' */ '~/pages/settings/integrations/List'))

const ProductsListPage = lazyLoad(() => import(/* webpackChunkName: 'products-list' */ '~/pages/catalog/products/ProductsListPage'))
const ProductDetailPage = lazyLoad(() => import(/* webpackChunkName: 'product' */ '~/pages/catalog/products/ProductDetailPage'))

const PlanDetailPage = lazyLoad(() => import(/* webpackChunkName: 'plan-detail' */ '~/pages/catalog/plans/PlanDetailPage'))
const PlansListPage = lazyLoad(() => import(/* webpackChunkName: 'plans-list' */ '~/pages/catalog/plans/PlansListPage'))

const FeaturesListPage = lazyLoad(() => import(/* webpackChunkName: 'features-list' */ '~/pages/catalog/features/FeaturesListPage'));
const FeatureDetailPage = lazyLoad(() => import(/* webpackChunkName: 'feature-detail' */ '~/pages/catalog/features/FeatureDetailPage'));
const FeatureEditPage = lazyLoad(() => import(/* webpackChunkName: 'feature-edit' */ '~/pages/catalog/features/FeatureEditPage'))

const SecretListPage = lazyLoad(() => import(/* webpackChunkName: 'secret-list' */ '~/pages/secret/SecretListPage'));
const DevelopersPage = lazyLoad(() => import(/* webpackChunkName: 'clients-list' */ '~/pages/settings/clients/DevelopersPage'));

const PaywallsListPage = lazyLoad(() => import(/* webpackChunkName: 'paywalls-list' */ '~/pages/convert/paywalls/PaywallsListPage'));
const OrganizationDetailPage = lazyLoad(() => import(/* webpackChunkName: 'paywalls-list' */ '~/pages/settings/OrganizationDetailPage'));
const ConnectionDetailPage = lazyLoad(() => import(/* webpackChunkName: 'connection-detail' */ '~/pages/settings/connections/ConnectionDetailPage'));
const ProfileDetailPage = lazyLoad(() => import(/* webpackChunkName: 'paywalls-list' */ '~/pages/settings/profile/ProfileDetailPage'));

const OrganizationCreatePage = lazyLoad(() => import(/* webpackChunkName: 'paywalls-list' */ '~/pages/mgmt/OrganizationCreatePage'));
const OrganizationJoinPage = lazyLoad(() => import(/* webpackChunkName: 'paywalls-list' */ '~/pages/mgmt/OrganizationJoinPage'));


const catalogRoutes: CustomRouteObject[] = [
  {
    private: true,
    element: <CatalogLayout />,
    children: [
      { path: '/catalog', element: <Navigate to="/catalog/pricing" replace={true} />, private: true, },

      { path: '/catalog/pricing', element: <PricingSchemeListPage />, private: true, },
      { path: '/catalog/pricing/schemes/:schemeId', element: <PricingSchemeDetailPage />, private: true, },
      { path: '/catalog/pricing/packages/create', element: <SchemePackageCreatePage />, private: true, },

      { path: '/catalog/pricing/packages/:packageId', element: <PackageDetailPage />, private: true, },

      { path: '/catalog/product_families', element: <ProductFamiliesIndexPage />, private: true, },
      { path: '/catalog/product_families/create', element: <CreateEditProductFamilyPage />, private: true, },
      { path: '/catalog/product_families/:familyId', element: <CreateEditProductFamilyPage update />, private: true, },


      { path: '/catalog/products', element: <ProductsListPage />, private: true, },
      { path: '/catalog/products/create', element: <ProductCreatePage />, private: true, },
      { path: '/catalog/products/:productId', element: <ProductDetailPage />, private: true, },

      // { path: '/catalog/plans', element: <PlansListPage />, private: true, },
      { path: '/catalog/pricing/plans/:planId', element: <PlanDetailPage />, private: true, },
      { path: '/catalog/pricing/plans/create', element: <PlanCreatePage />, private: true, },
    ]
  }
];

const featuresRoutes: CustomRouteObject[] = [
  {
    private: true,
    element: <><Outlet /></>,
    children: [
      { path: '/catalog/metrics/create', element: <CreateMetricPage />, private: true },
      { path: '/catalog/metrics', element: <MetricsListPage />, private: true },
      { path: '/catalog/metrics/:id/edit', element: <MetricEditPage />, private: true },

      { path: '/catalog/features', element: <FeaturesListPage />, private: true },
      { path: '/catalog/features/:featureId', element: <FeatureDetailPage />, private: true },
      { path: '/catalog/features/create', element: <FeatureCreatePage />, private: true },
      { path: '/catalog/features/:featureId/edit', element: <FeatureEditPage />, private: true },

      { path: '/catalog/feature_sets/create', element: <FeatureSetCreatePage />, private: true, },
      { path: '/catalog/feature_sets', element: <FeatureSetListPage />, private: true, },
      { path: '/catalog/feature_sets/create', element: <FeatureSetCreatePage />, private: true, },
      { path: '/catalog/feature_sets/:id/edit', element: <FeatureSetEditPage />, private: true, },
    ]
  }
]

const convertRoutes: CustomRouteObject[] = [

  { path: `/account/signals`, element: <SignalsListPage />, private: true },
  { path: `/account/activities`, element: <ActivitiesListPage />, private: true },

  { path: `/convert`, element: <Navigate to="/convert/home" replace={true} />, private: true },
  { path: `/convert/home`, element: <ConvertHomePage />, private: true },

  { path: `/convert/paywalls/:paywallId/sandbox`, element: <WorkflowSandboxPage />, private: true },
  { path: `/convert/paywalls/:paywallId/view`, element: <PaywallViewPage />, private: true },

  { path: `/convert/workflows`, element: <WorkflowsListPage />, private: true },
  { path: `/convert/workflows/:id`, element: <WorkflowDetailPage />, private: true },
  { path: `/convert/workflows/:id/paywalls`, element: <WorkflowPaywallsPage />, private: true },
  { path: `/convert/workflows/:id/results`, element: <WorkflowResultsPage />, private: true },
  { path: `/convert/workflows/:id/participation`, element: <WorkflowParticipationPage />, private: true },
  { path: `/convert/workflows/:id/revenue`, element: <WorkflowRevenuePage />, private: true },
  { path: `/convert/workflows/:id/events`, element: <WorkflowEventsPage />, private: true },


  { path: `/convert/workflows/:workflowId/edit`, element: <WorkflowEditPage />, private: true },
  { path: `/convert/workflows/create`, element: <WorkflowCreatePage />, private: true },
  { path: `/convert/workflows/:workflowId/paywalls/create`, element: <PaywallCreatePage />, private: true },

  { path: '/convert/paywalls/', element: <PaywallsListPage />, private: true, },
  { path: '/convert/paywalls/:paywallId/edit', element: <PaywallEditPage />, private: true, },

  // {path: '/convert/themes', element: <div>Themes</div>, private: true, },

  { path: '/customers', element: <CustomersListPage />, private: true, },
  { path: '/customers/:customerId', element: <CustomerDetailPage />, private: true, },

  { path: '/convert/flows/:flowId', element: <FlowDetailPage />, private: true, },
  { path: '/convert/elements', element: <ElementsListPage />, private: true, },
  { path: '/convert/elements/:elementId', element: <ElementDetailPage />, private: true, },

  { path: '/secret', element: <SecretListPage />, private: true, },
]

const settingsRoutes: CustomRouteObject[] = [
  {
    private: true,
    element: <SettingsLayout />,
    children: [
      { path: '/mgmt/usage_events', element: <UsageEventsListPage />, private: true },
      { path: '/mgmt/attributions', element: <AttributionsListPage />, private: true },
      { path: '/mgmt/agents', element: <AgentsListPage />, private: true },
      { path: '/billing/charges', element: <ChargesListPage />, private: true },

      { path: '/settings', element: <Navigate to="/settings/organization" replace={true} />, private: true },
      { path: '/settings/organization', element: <OrganizationDetailPage />, private: true },
      { path: '/settings/members', element: <TeamListPage />, private: true },
      { path: '/settings/developers', element: <DevelopersPage />, private: true },
      { path: '/settings/clients/:clientId', element: <ClientDetailPage />, private: true },
      { path: ['/settings/profile', '/settings'], element: <ProfileDetailPage />, private: true },

      { path: '/settings/integrations', element: <IntegrationsListPage />, private: true },
      { path: '/settings/integrations/create', element: <IntegrationCreatePage />, private: true },
      { path: '/settings/connections/:id', element: <ConnectionDetailPage />, private: true },
      { path: '/settings/theme', element: <ThemeEditPage />, private: true },
    ]
  }
]


function DashboardErrorPage({ error }: { error: number }) {
  return (
    <div className={"p-10"}>
      <h1 className={"text-5xl"}>Error: {error}</h1>
    </div>
  )
}

export const routes: CustomRouteObject[] = [
  {
    path: '*',
    private: false,
    element: <Error404 />
  },
  // {path: '/convert/paywalls/:paywallId/edit', element: <PaywallEditPage/>, private: true, },

  {
    element: <DashboardLayout />,
    children: [
      {
        path: "/",
        private: true,
        element: <Dashboard />,
      },
      {
        path: '/test',
        private: true,
        element: <TestPage />
      },
      {
        path: '*',
        private: true,
        element: <DashboardErrorPage error={404} />
      },
      { path: '/organizations/create', element: <OrganizationCreatePage />, private: true },
      { path: '/organizations/join', element: <OrganizationJoinPage />, private: true },
      ...featuresRoutes,
      ...catalogRoutes,
      ...[
        { path: '/catalog/pricing/schemes/create', element: <PricingSchemeCreatePage />, private: true, },
      ],
      ...convertRoutes,
      ...settingsRoutes,
    ],
  },

  ...authRoutes
]

interface PageWrapperProps {
  routeConfig: CustomRouteObject
  children: ReactNode
}

function useLocationHistory() {
  return {
    onRouteEnter: (routeConfig: CustomRouteObject, location: any) => {
      // console.log('onRouteEnter', routeConfig, location);
    }
  }
}

const PageWrapper = ({ children, routeConfig }: PageWrapperProps) => {
  const location = useLocation()
  const { onRouteEnter } = useLocationHistory()

  useEffect(() => {
    onRouteEnter(routeConfig, location)
  }, [location, routeConfig, onRouteEnter])

  return <>{children}</>
}

function Loader(props: any) {
  return (
    <div className="flex min-h-screen justify-center items-center text-sm">
      {props.children}
    </div>
  )
}
function LoadingIcon() {
  return (
    <div role="status" className="w-full h-full flex items-center justify-center">
      <Spinner />
    </div>
  )
}

const routesFormatter: (routesToFormat: CustomRouteObject[], loggedIn: boolean) => RouteObject[] = (
  routesToFormat,
  loggedIn,
) => {
  return routesToFormat.reduce<RouteObject[]>((acc, route) => {
    const routeConfig = {
      element: (
        <PageWrapper routeConfig={route}>
          <Suspense
            fallback={
              <Loader>
                <LoadingIcon />
              </Loader>
            }
          >
            {route.element}
          </Suspense>
        </PageWrapper>
      ),
      ...(route?.children ? { children: routesFormatter(route.children, loggedIn) } : {}),
    }

    if (route.index) {
      acc.push({
        index: true,
        ...routeConfig,
      } as RouteObject)
    } else if (!route.path) {
      acc.push(routeConfig)
    } else if (typeof route.path === 'string') {
      acc.push({
        path: route.path,
        ...routeConfig,
      })
    } else {
      ; (route.path as string[]).map((singlePath) => {
        acc.push({
          path: singlePath,
          ...routeConfig,
        })
      })
    }

    return acc
  }, [])
}

function useIsAuthenticated() {
  return {
    isAuthenticated: true
  }
}

export const RouteWrapper = () => {
  const { isAuthenticated } = useIsAuthenticated()
  const formattedRoutes = routesFormatter(routes, isAuthenticated)

  let element = useRoutes(formattedRoutes);

  return element;
}
