import { Heading, Table } from "@radix-ui/themes";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { useState } from "react";
import Pagination from "~/components/Pagination";
import { Link, useSearchParams } from "react-router-dom";

interface Signal {
  id: number;
  type: string;
  customer?: {
    id: string;
  }
  amount: string;
  amount_raw: string;
  category: string;
  metadata: Record<string, any>;
  effective_at: string;
  created_at: string;
}

interface PaginationMeta {
  current_page: number;
  from: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

interface SignalResponse {
  data: Signal[];
  meta: PaginationMeta;
}

export default function SignalsListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page')) || 1;
  
  const { data, error } = useSWR<SignalResponse>(
    `/account/signals?page=${currentPage}`,
    getFetcher
  );

  const handlePageChange = (page: number) => {
    setSearchParams({ page: page.toString() });
  };

  return (
    <div className="p-10 space-y-4">
      <div className="flex items-center justify-between">
        <Heading size="6">Signals</Heading>
      </div>

      <div>
        {error && <div>Failed to load</div>}
        {(!data && !error) && <div>Loading...</div>}
        {data && (
          <>
            <Table.Root>
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell>ID</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Customer</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Type</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Category</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Amount</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Effective Date</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Created Date</Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {data.data.map((signal) => (
                  <Table.Row key={signal.id}>
                    <Table.Cell>{signal.id}</Table.Cell>
                    <Table.Cell>
                      <Link className="text-link" to={`/customers/${signal.customer?.id}`}>{signal.customer?.id}</Link>
                    </Table.Cell>
                    <Table.Cell>{signal.type}</Table.Cell>
                    <Table.Cell>{signal.category}</Table.Cell>
                    <Table.Cell className={`
                      ${parseInt(signal.amount_raw) > 0 ? 'text-green-600' : ''}
                      ${parseInt(signal.amount_raw) < 0 ? 'text-red-600' : ''}
                      ${parseInt(signal.amount_raw) === 0 ? 'text-gray-500' : ''}
                    `}>
                      {parseInt(signal.amount_raw) > 0 ? '+' : ''}{signal.amount}
                    </Table.Cell>
                    <Table.Cell>{new Date(signal.effective_at).toLocaleDateString()}</Table.Cell>
                    <Table.Cell>{new Date(signal.created_at).toLocaleDateString()}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>

            <Pagination
              resource={data}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </div>
    </div>
  );
}